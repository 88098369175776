import React from "react";
import { InlineWidget } from "react-calendly";
import "./booking.css";

function Booking() {
  return (
    <div id="booking" className="mt-4">
      <div className="section-center">
        <div className="container">
          <div className="row">
            <div className="booking-form">
              <div className="form-header">
                <h2>BOOK AN APPOINTMENT</h2>
                <p>We Offer Virtual and In-Person Appointments</p>
              </div>
              <form>
                <InlineWidget
                  url="https://calendly.com/faye563/30min"
                  utm={{
                    utmCampaign: "Spring Sale 2019",
                    utmContent: "Shoe and Shirts",
                    utmMedium: "Ad",
                    utmSource: "Facebook",
                    utmTerm: "Spring",
                  }}
                  style={{ maxHeight: "300px" }}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Booking;
