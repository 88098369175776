import { Routes, Route } from "react-router-dom";
import Booking from "./booking/Booking";
import NavBar from "./booking/Navbar";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Booking />} />
      </Routes>
    </div>
  );
}

export default App;
