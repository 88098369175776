import React from "react";
import { Container, Navbar } from "react-bootstrap";

function NavBar() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="https://mbspsych.org" target="_blank">
          <img src="../assets/img/cropped-MBS.png" alt="logo" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default NavBar;
